'use client';

import { useQueryString } from '@hooks/client';
import { useRouter } from 'next/navigation';
import React from 'react';

import { tabs1, tabs2 } from '@/mocks/header-tabs';
import { Portal } from '@/shared/portal';
import { Illustration } from '@/shared/ui';
import { Tabs } from '@/shared/ui/tabs';

import styles from './header-tabs.module.scss';

export const HeaderTabsFeatureMobile = () => {
  const router = useRouter();
  const { createQueryString } = useQueryString();

  return (
    <Portal selector={'#header-portal'}>
      <Tabs
        onTabsChange={() => {}}
        defaultActiveTab={'Hot Sale'}
        className={styles.container}
      >
        <Tabs.Item
          value={'Hot Sale'}
          variant="secondary"
          onClick={() =>
            router.push(
              createQueryString({
                pathname: '/shop',
                name: 'sale',
                value: 'hot-sale',
                asMultipleParams: true,
              }),
            )
          }
          iconsLeft={
            <Illustration
              name={'fire-square'}
              spriteName={'icons'}
              size={20}
              style={{ color: '#EB3C3C' }}
            />
          }
        >
          Hot Sale
        </Tabs.Item>
        <Tabs.Item
          value={'New'}
          variant="tertiary"
          onClick={() =>
            router.push(
              createQueryString({
                pathname: '/shop',
                name: 'category',
                value: 'new',
                asMultipleParams: true,
              }),
            )
          }
          iconsLeft={
            <Illustration
              name={'lightning'}
              spriteName={'icons'}
              size={20}
              style={{ color: '#40BB18' }}
            />
          }
        >
          New
        </Tabs.Item>
        {tabs2.map((tab, idx) => (
          <Tabs.Item
            key={`header-${idx}-${tab.name}-1`}
            value={tab.name}
            onClick={() =>
              router.push(
                createQueryString({
                  pathname: '/shop',
                  name: 'category',
                  value: tab.name.toLowerCase(),
                  asMultipleParams: false,
                }),
              )
            }
            variant="primary"
          >
            {tab.name}
          </Tabs.Item>
        ))}
        {tabs1.map((tab, idx) => (
          <Tabs.Item
            key={`header-${idx}-${tab.name}-1`}
            value={tab.name}
            onClick={() =>
              router.push(
                createQueryString({
                  pathname: '/shop',
                  name: 'property',
                  value: tab.name.toLowerCase(),
                  enableToggle: false,
                }),
              )
            }
            tagCategory={tab.icon}
            variant="primary"
          >
            {tab.name}
          </Tabs.Item>
        ))}
      </Tabs>
    </Portal>
  );
};
