'use client';
import { useQueryString } from '@hooks/client';
import { useProtectedRouter } from '@hooks/client/use-protected-router';
import {
  FooterLink,
  footerLinks,
  footerSocialLinks,
} from '@widgets/desktop/footer/ui/utils';
import cn from 'clsx';
import { motion } from 'framer-motion';
import { router } from 'next/client';
import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';
import React, { useEffect, useMemo, useState } from 'react';

import { Filter } from '@/shared/types/common';
import {
  Accordion,
  Button,
  Divider,
  Illustration,
  Typography,
} from '@/shared/ui';
import { DropListItem } from '@/shared/ui/drop-list-item';
import { Icons } from '@/shared/ui/illustration/illustration';
import { DropListSortMobile } from '@/shared/ui/mobile/drop-list-sort';
import { DropList } from '@/shared/ui/mobile/input-search/types';

import styles from './footer.module.scss';
export const FooterWidgetMobile = () => {
  const footerClasses = cn(styles['container']);

  const pathname = usePathname();

  const hideAccordion =
    pathname.includes('inventory') ||
    pathname.includes('cart') ||
    pathname.includes('categories') ||
    RegExp('^\\/[a-z]{2}\\/faq(\\/[a-z-]*)$').test(pathname);
  const hideFooter =
    pathname.includes('inventory') ||
    pathname.includes('not-found') ||
    pathname.includes('drop');

  const Categories: Filter = {
    label: 'Categories',
    filter: '',
    group: '',
    icon: (
      <Illustration
        name={'widget'}
        size={22}
        style={{ color: 'var(--global-purple600)' }}
        spriteName={'icons'}
      />
    ),
  };

  const MyAccount: Filter = {
    label: 'My account',
    filter: '',
    group: '',
    icon: (
      <Illustration
        name={'user'}
        size={22}
        style={{ color: '#FE9920' }}
        spriteName={'icons'}
      />
    ),
  };

  const Support: Filter = {
    label: 'Support',
    filter: '',
    group: '',
    icon: (
      <Illustration
        name={'question-square'}
        size={22}
        style={{ color: 'var(--global-green600)' }}
        spriteName={'icons'}
      />
    ),
  };

  if (hideFooter) return null;

  return (
    <footer id={'mobile-footer'} className={footerClasses}>
      <div className={styles['border']}>
        <div className={styles['content']}>
          <div className={styles['social-links']}>
            {footerSocialLinks?.slice(0, 6).map(item => {
              return (
                <Button
                  href={'#'}
                  as={Link}
                  key={item.id}
                  iconLeft={
                    <Illustration
                      spanTagClassName={styles['social-icon']}
                      name={item.icon as keyof Icons}
                      spriteName={'icons'}
                    />
                  }
                  variant={'secondary'}
                  className={styles['social-link']}
                />
              );
            })}
          </div>
          <Typography className={styles['deskr']}>
            BloxWay is not affiliated, associated, or partnered with UpliftGames
            LLC and Roblox Corporation in any way. We are not authorized,
            endorsed, or sponsored by Uplift Games LLC and Roblox Corporation.
          </Typography>
          {!hideAccordion && (
            <div className={styles['accordions']}>
              <FooterWidgetMobile.LinksList
                links={[
                  ...footerLinks.categories.group_0,
                  ...footerLinks.categories.group_1,
                ]}
                trigger={Categories}
              />
              <FooterWidgetMobile.LinksList
                links={[
                  ...footerLinks.account.group_0,
                  ...footerLinks.account.group_1,
                ]}
                trigger={MyAccount}
              />
              <FooterWidgetMobile.LinksList
                links={[...footerLinks.support.group_1]} //...footerLinks.support.group_0,
                trigger={Support}
              />
            </div>
          )}

          <Typography className={styles['deskr']}>
            BENEFICIARY: CRYSTAL FUTURE OU
            <br /> REG. NUMBER: 14198230
            <br /> BENEFICIARY’S ADDRESS: HARJU MAAKOND, TALLINN, KESKLINNA
            LINNAOSA, PIKK TN 7-5, 10123
          </Typography>
          <Typography className={styles['rights']}>
            © 2024 BloxWay - largest roblox marketplace. All Rights Reserved.
          </Typography>
          <div className={styles['agreements']}>
            <Button
              href={'/policy/user'}
              as={Link}
              text={'User agreement'}
              width={'full-w'}
              iconLeft={
                <Illustration
                  spanTagClassName={styles['social-icon']}
                  name={'user-check-rounded'}
                  spriteName={'icons'}
                />
              }
              variant={'secondary'}
              className={styles['agreement-link']}
            />
            <Button
              href={'/policy/privacy'}
              as={Link}
              text={'Privacy policy'}
              width={'full-w'}
              iconLeft={
                <Illustration
                  spanTagClassName={styles['social-icon']}
                  name={'notebook-footer'}
                  spriteName={'icons'}
                />
              }
              variant={'secondary'}
              className={styles['agreement-link']}
            />
          </div>
        </div>
      </div>
    </footer>
  );
};

type FooterWidgetMobileLinksListProps = {
  trigger: Filter;
  links: FooterLink[];
};

// eslint-disable-next-line react/display-name
FooterWidgetMobile.LinksList = ({
  trigger,
  links,
}: FooterWidgetMobileLinksListProps) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [openAccordion, setOpenAccordion] = useState(false);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const router = useProtectedRouter();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { createQueryString } = useQueryString();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const pathname = usePathname();

  const handlePress = (href: string, isCategory?: boolean) => () => {
    if (isCategory) {
      router.push(
        createQueryString({
          pathname: '/shop',
          name: 'category',
          value: href.toLowerCase(),
          asMultipleParams: false,
        }),
      );
    } else {
      router.push(href);
    }
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    setOpenAccordion(false);
  }, [pathname]);

  return (
    <Accordion
      as={motion.section}
      toggle={() => {
        setOpenAccordion(prev => !prev);
      }}
      isOpened={openAccordion}
      triggerSlot={
        <DropListSortMobile
          variant={'secondary'}
          isOpened={openAccordion}
          selectedItem={trigger}
        />
      }
      listSlot={
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.35,
              staggerChildren: 0.3,
              delayChildren: 0.3,
            },
            height: 'max-content',
          }}
          exit={{
            opacity: 0,
            height: 0,
            transition: {
              staggerChildren: 0.07,
              staggerDirection: -1,
              duration: 0.35,
            },
          }}
          className={styles['links-list']}
        >
          {Array.isArray(links) &&
            links.length > 0 &&
            links.map((link, idx) => {
              return (
                <motion.div key={`footer-link-${idx}-icon-${link.icon}`}>
                  <DropListItem
                    className={styles['link-container']}
                    onPress={handlePress(link.href, link.isCategory)}
                    title={link.text}
                    iconLeft={
                      <Illustration
                        size={22}
                        style={{ color: link.color }}
                        name={link.icon as keyof Icons}
                        spriteName={'icons'}
                      />
                    }
                  />
                </motion.div>
              );
            })}
        </motion.div>
      }
    />
  );
};
