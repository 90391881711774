'use client';
import { BannerImageEntityMobile } from '@entities/mobile/banner-image';
import {
  CardItemLiveEntityMobile,
  CardItemShopEntityMobile,
  CardLiveFeedEntityMobile,
  CardMarketEntityMobile,
} from '@entities/mobile/cards';
import { AddToCartFeatureMobile } from '@features/mobile/add-to-cart';
import { AddToFavoriteFeatureMobile } from '@features/mobile/add-to-favorite';
import { HeaderTabsFeatureMobile } from '@features/mobile/header-tabs';
import { SelectPetPropertiesFeature } from '@features/select-pet-properties';
import {
  BannerVariantWithAction,
  ContentBannerProps,
  ContentWidgetCategoriesProps,
  ContentWidgetItemsBlockProps,
  ContentWidgetLiveFeedProps,
} from '@widgets/mobile/content/ui/content.types';
import cn from 'clsx';
import { useRouter, useSearchParams } from 'next/navigation';
import React, { useEffect, useRef, useState } from 'react';
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useMediaQuery } from 'usehooks-ts';
import { useIsClient } from 'usehooks-ts';

import { mockedData } from '@/mocks/items';
import { mockedCategoriesMobile1 } from '@/mocks/market-categories';
import { SeeAllButton } from '@/shared/components/see-all-button';
import {
  BLOGGERS_COLLABORATION,
  CONSTANT_PROMOTION,
} from '@/shared/constants/links';
import { WithCurrency, WithShopItems } from '@/shared/hocs';
import { GlobalItem } from '@/shared/types/common';
import { Illustration, Label } from '@/shared/ui';
import { Plate } from '@/shared/ui/desktop/plate';
import { AdditionalInfoMobile } from '@/shared/ui/mobile/additional-info';
import { BottomSheetUi } from '@/shared/ui/mobile/bottom-sheet';
import { CartItemType } from '@/store/cart/cart';
import { useStore } from '@/store/context';

import styles from './content.module.scss';
import swiperStyles from './swiper.module.scss';

export const ContentWidgetMobile = () => {
  const isClient = useIsClient();

  const auth = useStore().authorization;

  const searchParams = useSearchParams();

  const router = useRouter();

  const isMediumScreen = useMediaQuery('(min-width: 428px)');

  const topBannersEndRef = useRef<HTMLDivElement | null>(null);

  const screenSize = isClient ? (isMediumScreen ? 'large' : 'small') : null;

  const promocode = searchParams.get('promocode');

  const scrollToItems = () => {
    topBannersEndRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const topBanners: BannerVariantWithAction[] = [
    {
      variant: 'pets-giveaway',
      pressAction: () => {},
    },
    {
      variant: 'concept1',
      pressAction: scrollToItems,
    },
    {
      variant: 'concept2',
      pressAction: () => router.push('/shop'),
    },
    {
      variant: 'concept3',
      pressAction: scrollToItems,
    },
  ];
  const bottomBanners: BannerVariantWithAction[] = [
    {
      variant: 'bloggers-collaboration',
      pressAction: () => window.location.assign(BLOGGERS_COLLABORATION),
    },
    {
      variant: 'constant-promotion',
      pressAction: () => window.location.assign(CONSTANT_PROMOTION),
    },
    {
      variant: 'social-boost',
      pressAction: () => {},
    },
  ];

  useEffect(() => {
    if (promocode) {
      router.push('/');
      auth.openPromoLanding();
    }
  });

  return (
    <div className={styles['content-widget__container']}>
      <div className={styles['wrapper']}>
        <div>
          <ContentWidgetMobile.Banner
            banners={topBanners}
            screenSize={screenSize}
            sliderSize={'l'}
          />
          <div ref={topBannersEndRef} />
        </div>
        <ContentWidgetMobile.LiveFeed items={mockedData} />
        <WithShopItems>
          {({ items }) => {
            return (
              <ContentWidgetMobile.ItemsBlock
                items={items}
                headerSlot={
                  <>
                    <Label
                      labelVariant={'red'}
                      icon={'fire-square-red'}
                      variant={'primary'}
                    >
                      Hot Sales
                    </Label>
                    <SeeAllButton
                      href={'/shop?sort=popular'}
                      variant={'secondary'}
                    />
                  </>
                }
              />
            );
          }}
        </WithShopItems>

        <ContentWidgetMobile.Banner
          banners={[...bottomBanners, ...bottomBanners]}
          screenSize={screenSize}
          sliderSize={'m'}
        />

        <ContentWidgetMobile.Categories categories={mockedCategoriesMobile1} />

        <WithShopItems>
          {({ items }) => {
            return (
              <ContentWidgetMobile.ItemsBlock
                items={items}
                headerSlot={
                  <>
                    <Label
                      labelVariant={'black'}
                      icon={'star-circle'}
                      variant={'primary'}
                    >
                      Exclusive Items
                    </Label>
                    <SeeAllButton
                      href={'/shop?sort=popular'}
                      variant={'secondary'}
                    />
                  </>
                }
              />
            );
          }}
        </WithShopItems>

        <WithShopItems>
          {({ items }) => {
            return (
              <ContentWidgetMobile.ItemsBlock
                items={items}
                headerSlot={
                  <>
                    <Label
                      labelVariant={'yellow'}
                      icon={'graph-up'}
                      variant={'primary'}
                    >
                      Popular items
                    </Label>
                    <SeeAllButton
                      href={'/shop?sort=popular'}
                      variant={'secondary'}
                    />
                  </>
                }
              />
            );
          }}
        </WithShopItems>
      </div>
      <HeaderTabsFeatureMobile />
    </div>
  );
};

// eslint-disable-next-line react/display-name
ContentWidgetMobile.Banner = ({
  className,
  sliderSize,
  banners,
  screenSize,
}: ContentBannerProps) => {
  const bannerClasses = cn(styles['content-widget__banner'], className);
  const bannerSlideClasses = cn(
    swiperStyles['slide'],
    swiperStyles[`slide-${sliderSize}`],
  );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isLargeMobile = useMediaQuery('(min-width: 744px)');

  const swiperConfig = isLargeMobile
    ? {
        centeredSlides: true,
        slidesPerView: 1.7,
      }
    : {
        slidesPerView: 1,
      };

  if (!screenSize) {
    return (
      <div className={bannerClasses}>
        <Plate className={swiperStyles.plate} />
      </div>
    );
  }

  return (
    <div className={bannerClasses}>
      <Swiper
        pagination={{
          clickable: true,
        }}
        loop
        autoplay
        {...swiperConfig}
        modules={[Autoplay, Pagination]}
        className={'swiper-mobile'}
      >
        {banners.map((banner, idx) => {
          return (
            <SwiperSlide key={`${idx}-src-mobile-slide`}>
              <div className={bannerSlideClasses} onClick={banner.pressAction}>
                <BannerImageEntityMobile
                  variant={banner.variant}
                  size={screenSize}
                />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

// eslint-disable-next-line react/display-name
ContentWidgetMobile.LiveFeed = ({ items }: ContentWidgetLiveFeedProps) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [sheetOpened, setSheetOpened] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [selectedItem, setSelectedItem] = useState<GlobalItem | null>(null);

  const toggleSheet = (item: GlobalItem | null) => {
    setSheetOpened(prev => !prev);
    setSelectedItem(item);
  };

  const itemprice = 3;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const router = useRouter();

  return (
    <div className={styles['content-widget__live-feed-container']}>
      <Label
        className={styles['label']}
        labelVariant={'purple'}
        icon={'graph-new-up'}
        variant={'primary'}
      >
        Live Sales
      </Label>
      <div className={styles['content-widget__live-feed']}>
        {Array.isArray(items) &&
          items.length > 0 &&
          items?.map((item, idx) => (
            <CardLiveFeedEntityMobile
              handleInfoClick={() => toggleSheet(item)}
              key={`${idx}-${item.info.title}-${item?.info?.price?.old}`}
              item={item.item}
              rarity={item.rarity}
            />
          ))}
      </div>

      <BottomSheetUi isOpen={sheetOpened} handleClose={() => toggleSheet(null)}>
        <BottomSheetUi.Content>
          <BottomSheetUi.Header>About purchase</BottomSheetUi.Header>

          <div className={styles['content-widget__live-feed-sheet-info']}>
            {selectedItem && (
              <CardItemLiveEntityMobile
                leftSlot={
                  <CardItemLiveEntityMobile.Item
                    item={selectedItem.item}
                    rarity={selectedItem.rarity}
                  />
                }
                middleSlot={
                  <CardItemLiveEntityMobile.ItemInfo
                    title={selectedItem.info.title}
                    age={selectedItem.info.age}
                  />
                }
                rightSlot={
                  <CardItemLiveEntityMobile.ItemProperties
                    tagCategory={selectedItem.tagCategory}
                    rarity={selectedItem.rarity}
                  />
                }
              />
            )}
            <AdditionalInfoMobile
              variant={'success'}
              icon={
                <Illustration
                  style={{ color: 'green' }}
                  name={'tag-price'}
                  spriteName={'icons'}
                  spanTagClassName={styles.icon}
                />
              }
            >
              Item price:{' '}
              <WithCurrency>{selectedItem?.info.price.current}</WithCurrency>
            </AdditionalInfoMobile>
          </div>
          <div className={styles['content-widget__live-feed-sheet-btns']}>
            <BottomSheetUi.Button
              onClick={
                selectedItem
                  ? () =>
                      router.push(
                        `/shop/${selectedItem?.category}/${selectedItem?.id}`,
                      )
                  : () => {}
              }
              text={'Go to item'}
              variant={'primary'}
              iconRight={
                <Illustration
                  id={'btn-icon'}
                  name={'square-arrow-right'}
                  spriteName={'icons'}
                />
              }
            />
            <BottomSheetUi.Button
              text={'Close'}
              variant={'secondary'}
              onClick={() => toggleSheet(null)}
            />
          </div>
        </BottomSheetUi.Content>
      </BottomSheetUi>
    </div>
  );
};

// eslint-disable-next-line react/display-name
ContentWidgetMobile.ItemsBlock = ({
  headerSlot,
  items,
}: ContentWidgetItemsBlockProps) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const router = useRouter();

  return (
    <div className={styles['content-widget__items-block']}>
      {headerSlot && <div className={styles['header']}>{headerSlot}</div>}
      <div className={styles['items']}>
        {items?.map((item, idx) => {
          let itemReadyToCart: CartItemType = {
            ...item,
            status: 'available',
            quantity: 1,
          };

          return (
            <CardItemShopEntityMobile
              onClick={() => router.push(`/shop/${item.category}/${item?.id}`)}
              key={`card-${idx}-${item.info.title}-${item?.info?.price?.old}`}
              item={item.item}
              rarity={item.rarity}
              info={{
                title: item?.info?.title,
                price: {
                  old: item?.info?.price?.old,
                  current: item?.info?.price?.current,
                },
              }}
              propertiesSlot={<SelectPetPropertiesFeature item={item} />}
              tagsInfo={item?.tagsInfo}
              topRightSlot={
                <AddToFavoriteFeatureMobile variant={'primary'} id={item.id} />
              }
              bottomSlot={<AddToCartFeatureMobile item={itemReadyToCart} />}
            />
          );
        })}
      </div>
    </div>
  );
};

// eslint-disable-next-line react/display-name
ContentWidgetMobile.Categories = ({
  categories,
}: ContentWidgetCategoriesProps) => {
  return (
    <div className={styles['content-widget__categories']}>
      {categories?.map(({ id, ...category }, idx) => {
        return (
          <CardMarketEntityMobile
            key={`mobile-${idx}-${category.title}-${category.item}`}
            {...category}
          />
        );
      })}
    </div>
  );
};
