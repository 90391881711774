import { useTimer } from '@hooks/client';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import { VERIFY_EMAIL } from '@/shared/api/endpoints';
import { useUser } from '@/shared/api/user/useUser';
import usersApi from '@/shared/api/usersApi';
import { Button, Illustration } from '@/shared/ui';
import { useStore } from '@/store/context';

import styles from './email-verify.module.scss';

export const EmailVerifyFeatureMobile = observer(() => {
  const notificationStore = useStore().notifications;
  const auth = useStore().authorization;
  const { user } = useUser();

  const {
    timer,
    count,
    resetCountdown,
    startCountdown,
    stopCountdown,

    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useTimer({
    duration: 60,
  });

  const isCooldown = count !== 60 && count !== 0;

  const verifyButtonClasses = cn(styles.btn, styles['verify-btn'], {
    [styles['verify-btn-disabled']]: isCooldown,
  });

  const handleLinkSent = async () => {
    try {
      await usersApi.get(VERIFY_EMAIL);
      notificationStore?.addNotification({
        variant: 'success',
        title: 'Sent!',
        subtitle: 'A letter with a link has been sent to your email!',
        delay: 5,
        id: 'email-successfully-sent',
      });
      resetCountdown();
      startCountdown();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    stopCountdown();
  }, []);

  if (user?.isEmailVerified) {
    return (
      <Button
        text={'Verified'}
        disabled
        iconRight={
          <Illustration name={'check-square'} size={22} spriteName="icons" />
        }
        variant={'quaternary'}
        width={'content'}
        className={styles.btn}
      />
    );
  }

  return (
    <Button
      onClick={handleLinkSent}
      variant={'primary'}
      className={verifyButtonClasses}
      disabled={isCooldown}
      width={'content'}
      iconRight={
        !isCooldown && (
          <Illustration name={'check-square'} size={22} spriteName="icons" />
        )
      }
      text={
        isCooldown ? `Resend the letter in ${timer.substring(3)}` : 'Verify'
      }
    />
  );
});
